import Config from '../config'
import io from 'socket.io-client'
import SDK from 'sdk-api'

const sdk = new SDK()

const socket = io(Config.remote.server, { autoConnect: false })
let remoteChannelToConnect = ''

const sendMsgToUI = (method, params) => {
  const payload = JSON.stringify({
    method,
    params,
  })

  iframePlayerUI.contentWindow.postMessage(payload, '*')
}

socket.on('connect', function () {
  socket.emit('joinRemote', remoteChannelToConnect)
})

socket.on('updateState', function (status) {
  sendMsgToUI('updateState', status)
})

async function login(data){
  const profile = await sdk.login(data.username, data.password)
  remoteChannelToConnect = profile.apikey
  socket.connect()
  sendMsgToUI('updateState', {
    loading: true,
    profile: { license: {}, channels: []},
  })
}

// UI settings
const iframePlayerUI = document.getElementById('playerUI')
const iframePlayerUIUrl = Config.ui.server

// Start listening for new messages
window.addEventListener('message', async (event) => {
  if (!event.data) {
    return
  }

  const data = JSON.parse(event.data)

  if (data.method === 'login') {
    login(data.params)
    return
  }

  console.log('new msg from ui', data);
  socket.emit('rpc', data.method, data.params)
}, false)


// Load UI
iframePlayerUI.src = iframePlayerUIUrl
iframePlayerUI.onload = () => {
  sendMsgToUI('updateState', {
    loading: false,
    profile: {},
  })
}
const devMode = process.env.NODE_ENV == 'dev'
const Config = {
    ui: {
        server: devMode ? 'http://localhost:2001/' : 'https://sonoro-ui-beta.now.sh',
    },
    remote: {
        server: 'https://getmedia-service-remote.herokuapp.com',
    }
}

export default Config
